import React from "react"
import itemStyle from "./item.module.css"

export default props => <section className={itemStyle.container}>
	<div className="title">
		<h2>{props.order} - {props.title}</h2>
	</div>

	<div className={itemStyle.itemWrapper}>
	    <div className={itemStyle.imageWrapper}>
	        <img src={'/img/' + props.order + '.jpg'} alt={props.title} />
	    </div>
	    <div className={itemStyle.contenido}>
	        
	        <h3>{props.latinTitle}</h3>
	        <div className="textContent" dangerouslySetInnerHTML={{__html: props.content}}>

	        </div>
	    </div>
    </div>


</section>