import React from "react"
import Item from "../components/item"
import Layout from "../components/layout"
import { graphql} from "gatsby"

export default ({ data }) => <Layout>

    <div className="portada">
        <p>
            <div className="a">A Guide to the common</div>
            <div className="b">WILD FLOWERS <span>of</span></div>
            <div className="c">The ALICANTE COAST</div>
            <div className="d">Featuring Santa Pola & Gran Alacant</div>
            <div className="e">In Simple Language</div>
            <img src="/img/portada.jpg" alt="Wild Flowers of Santa Pola and Gran Alancat"/>
        </p>
        <div className="border"></div>
    </div>

    <div className="subcover">
        <p>All the photographs are original and were taken ‘in situ’ by the author.<br/>
            I wish to thank the staff  of the Clot de Galvany Nature Reserve for their help in compiling this publication.</p>
        <div className="images">
            <picture>
                <img src="/img/sub_mapa.jpg" alt=""/>
                <figcaption>A google map of the area, with added local features.</figcaption>
            </picture>

            <picture>
                <img src="/img/sub_faro.jpg" alt="The lighthouse of Gran Alancat, Santa Pola"/>
                <figcaption>The lighthouse (el faro) on the clifftops of Gran Alancat, which are rich in plant species.</figcaption>
            </picture>
        </div>
    </div>

    <div className="intro">
        <h1>INTRODUCTION</h1>

<p>The coast of the Alicante region, on the Costa Blanca, is generally warmer than other parts of Spain in the winter. This results in a variety of plants flowering in the winter and early Spring, though some species are vulnerable to drought. Santa Pola, Gran Alacant and close neighbour Carabassi, are only a few kms.from the city of Alicante. Santa Pola's own species of Sea Lavender (Limonium satapolense) adorns the rocky beaches in summer.</p>

<p><strong>The Santa Pola Sierras</strong>: These are the rocky hills and limestone pavement which stretch from Santa Pola to Gran Alacant. Stone pines have been planted but there are many natural open areas. The habitat of these hills is known as ‘garrigue’. Coastal garrigue adds to the diversity, but where it is not protected it is under threat of development.</p>

<p><strong>The Cape of Santa Pola (The Cabo)</strong>: The limestone cliffs, foreshore, rocky beaches and dunes are a vestige of the once natural Costa Blanca. A ‘Micro Reserve de Flora’ has been designated below the cliffs in the vicinity of the lighthouse, accessible from the beach road (Camino del Cabo).</p>

<p><strong>The Clot de Galvany</strong> is a nature reserve next to Gran Alacant, at Carabassi, consisting of various wetland habitats as well as dunes and hillside garrigue. It is therefore rich in wildlife and an example of the biodiversity of the area.</p>

<p><strong>The Salt Lakes</strong>: The Santa Pola Salt Lakes are an attraction for many types of birds, surrounded by salt-loving plants.</p>

<p>Enjoy your visit!</p>

</div>

    {data.allMarkdownRemark.edges.map(({ node }) => (
        <Item key={node.id}
            title={node.frontmatter.title}
            latinTitle={node.frontmatter.latinTitle}
            content={node.html}
            order={node.frontmatter.order}
        />
    ))}

    <div className="colophon">

    <img src="/img/the_end.jpg" alt="Wild Flowers, Santa Pola and Gran Alacant"/>

    <p>Whether you are visiting the area of Alicante or live here, I hope you have found this website useful. It is a non-profit making endeavour, in the hope that awareness can be raised concerning the wealth of natural plant life which exists here. Much of it can be seen by just wandering around the hills or the beach. Although some of the area is protected, there is still too much development occurring in parts of this lovely coast.</p>
 
<p>It is also hoped that in the future It could be translated into Spanish (perhaps by a student of English?). If you would like further information please email me at:
<a href="mailto:rose.jackson1@outlook.com">rose.jackson1@outlook.com</a></p>
<p>Feedback is very welcome.</p>
</div>

    
</Layout>

export const data = graphql`
    query {
        allMarkdownRemark(
            sort:{order: ASC, fields:[frontmatter___order]}
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title,
                        latinTitle,
                        order,
                    },
                    html
                }
            }
        },
    }
`